.back-to-top {
	position: fixed;
    background-color: var(--fcolor2);
    border: 1px solid var(--fcolor2);
	right: 30px;
	bottom: 30px;
	z-index: 11;
    transition: 0.5s all ease-in;
    animation: animed 1s infinite alternate ;
}
.back-to-top:hover {
    background-color: #fff;
    border: 1px solid var(--fcolor2);
	color: var(--fcolor2);
}
@media(max-width: 998px) {
    .back-to-top {
        right: 20px;
	    bottom: 30px;
    }
    
}


@keyframes animed {
    0% {
        transform: translateY(1);
    }
    100% {
        transform: translateY(-15px);
    }
}