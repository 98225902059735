.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transition: .2s;
    z-index: 50;
    position: fixed;
    top: 0;
    box-shadow:  0 0 5px rgba(0, 0, 0, 0.7);
}
.nav_logo {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    margin: 20px 0px 20px calc((100% - 1080px) * 0.5);
}
.nav{
    width: auto;
    display: flex;
    align-items: center;
    margin-right: 30px;
}
.dmn_list{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: 35px;
    list-style: none;
    margin: 0;
}
.menu_item a{
    font-size: 17px;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 33px;
    color: var(--fcolor2);
    transition: 0.4s ease;
}
.navTH a {
    font-family: 'Kanit', sans-serif;
}

.menu_item a:hover{
    color:var(--fcolor1);
}
.menu{
    display: none;
}
@media (max-width:1024px) {
    .nav_logo {
        margin: 30px 0px 23px calc((100% - 1000px) * 0.5);
    }
}

@media (min-width:220px) and (max-width:1024px) {
    .nav_logo {
        margin: 30px 0px 23px calc((100% - 200px) * 0.5);
    }
    .desktop_menu {
        display: none;
    }
    .buger-menu{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 9999;
        gap: 7px;
        
    }
    
    .burger-bar{
        width: 2em;
        height: 0.3em;
        background-color: var(--fcolor1);
        border-radius: 0.5em;
    }
    .menu {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        list-style: none;
        gap: 20px;
        width: 100%;
        height: 100vh;
        padding-top: 5em;
        background-color: rgb(124, 213, 255);
        position: absolute;
        top: 0;
        transition: 0.5s all ease-out;
    }
    .menu a {
        color: var(--fcolor1);
        font-weight: 400;
    }
    #navTH a {
        font-family: 'Kanit', sans-serif;
    }
    
    .hidden {
        right: -200%;
    }
    .visible {
        right: 0;
    }
    
    .burger-bar.clicked:nth-child(1){
        transform: rotate(45deg) translate(0.4em,0.5em);
        transition: .5s ease-out;
    }
    .burger-bar.clicked:nth-child(2){
        transform: scale(0.1);
        transition: .5s ease-out;
    }
    .burger-bar.clicked:nth-child(3){
        transform: rotate(135deg) translate(-0.5em,0.6em);
        transition: .5s ease-out;
    }
    
    .burger-bar.unclicked{
        transform: rotate(0) translate(0);
        transition: cubic-bezier(0.175,0.885,0.32,1.275) 0.5s;
    }
}
@media (max-width:550px) {
    .navbar {
        box-shadow: none;
        max-height: 145px;

    }
    .nav_logo {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 30px 0px 23px calc((100% - 160px) * 0.5);
    }
    
}