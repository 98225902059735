.feature {
    width: 100%;
    margin-top: 30px;
    background-color: #000;
}
.feature .content{
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    padding: 39px 0;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
    left: 91px;
    position: relative;
    
}
.feature-title h2{
    font-size: 48px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    color: var(--fcolor1);
    text-transform: uppercase;
    pointer-events: auto;
}
.feature_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 4em 0px 10px calc((100% - 980px) * 0.5);
    gap: 4em;
}
.contentTH h2{
    font-size: 42px !important;
    font-weight: 500;
    font-family: 'Kanit', sans-serif;
}
.topContent,.topBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7em;
}
.content-item span {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.2em;
    color: var(--fcolor1);
    font-weight: 600;
}
.content-item li {
    color: #fff;
    font-size: 12px;
    font-family: 'Nunito', sans-serif;
}
@media (min-width:768px) and (max-width:1024px) {
    .feature .content {
        margin: 0px 0px 10px calc((100% - 150px) * 0.5);
        left: -200px;
    }
    .feature-title h2{
        font-size: 42px;
    }
    .feature_content {
        margin: 4em 0px 10px calc((100% - 400px) * 0.5);
        gap: 3em;
    }
    .topContent,.topBottom {
        gap: 5em;
    }
    .content-item li {
        white-space: nowrap;
    }
    .contentTH h2{
        font-size: 34px !important;
        font-weight: 500;
    }
    
}
@media (min-width: 220px) and (max-width: 550px) {
    .feature .content {
      margin: 0px 0px 10px calc((100% - 200px) * 0.5);
      left: -65px;
    }
    .feature-title h2{
        font-size: 28px;
    }
    .feature_content {
        flex-direction: unset;
        margin: 2em 0px 10px calc((100% - 150px) * 0.5);
        gap: 2em;
    }
    .topContent,.topBottom {
        display: block;
    }
    .content-item li {
        white-space: nowrap;
    }
    .contentTH h2{
        font-size: 30px !important;
        font-weight: 500;
    }
  }