#contact {
    width: 100%;
    height: auto;
}
#contact .contactCT {
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    padding: 3em 0;
    left: 91px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
}
#contact .contactVideo{
    position: absolute;
    width: 100%;
    max-height: 540px;
    object-fit: cover;
    z-index: -1;
}
#contact .contactCT h2 {
    font-size: 48px;
    font-weight: 700;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    color: #fff;
}
.contactCTTH h2 {
    font-size: 42 !important;
    font-weight: 500 !important;
}
.contactTH{
    font-family: 'Kanit' !important;
}
.informationTH span {
    font-family: 'Kanit' !important;
    font-weight: 400 !important;
}
#contact .contactCT .content {
    position: relative;
    margin: 4em 0px 0 calc((100% - 980px) * 0.5);
    left: 0px;
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
#contact .information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3em;
}
#contact .information p {
    font-size: 17px;
    font-weight: 700;
    color: var(--fcolor1);
    text-transform: uppercase;
    margin-bottom:0.5em;
}
#contact .information a {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    text-decoration: none;
}
#contact .information span {
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    font-family: 'Nunito', sans-serif;
}
.contactForm {
    background-color: #ffffff6c;
    padding: 0.5em 1em 2.5em 1em;
    border-radius: 5px;
}
.formcontent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.formName {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2em;
}
.contactForm input {
    font-size: 15px;
    font-weight: 500;
    background: transparent;
    border-width: 0 0 1px 0;
    border-style:solid;
    border-color: #fff;
    padding: 0.5em;
    transition: .4s all ease;
}
.contactForm input:hover{
    border-color: #414141;

}
.contactForm input:focus{
    outline: none;
}

textarea {
    height: 135px;
    background: transparent;
    border-width: 0 0 1px 0;
    border-style:solid;
    border-color: #fff;
    padding: 0.5em;
    transition: .4s all ease;
}
textarea:hover{
    border-color: #414141;
}
textarea:focus{
    outline: none;
}
.formBtn {
    background:transparent;
    margin-top: 1em;
    border-radius: 20px;
    padding: 5px 30px;
    font-size: 14px;
    color: #000;
    border-width: 2px;
    border-color: var(--fcolor2);
    transition: 0.4s all ease;
}
.formBtn:hover{
    background:var(--fcolor2);
    border-color: var(--fcolor2);
}
@media (min-width: 768px) and (max-width: 1024px) {
    #contact .contactCT {
      margin: 0 auto;
      left: 0;
    }
    #contact .contactCT h2 {
        font-size: 42px;
    }
    .contactCTTH h2 {
        font-size: 34 !important;
        font-weight: 500 !important;
    }
    #contact .contactCT .content {
        position: relative;
        margin: 3em 0px 0 calc((100% - 680px) * 0.5);
        left: 0px;
        flex-direction: column;
        gap: 2em;
    }
    .formName {
        flex-direction: column;
        gap: 0.5em;
    }
  }
  @media (max-width:1024px) {
    .contact {
        background-image: url(/public/images/bg3.webp);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 20;
    }
    .contactVideo {
        display: none;
    }
}
@media (min-width:220px) and (max-width:550px) {
    #contact .contactCT {
        margin: 0 auto;
        left: 0;
      }
      #contact .contactCT h2 {
        font-size: 28px;
    }
    #contact .contactCT .content {
        margin: 0 auto;
        left: 0;
        flex-direction: column;
        gap: 2em;
    }
    .formName {
        flex-direction: column;
        gap: 0.5em;
    }
    .contactCTTH h2 {
        font-size: 30 !important;
        font-weight: 500 !important;
    }
    
}