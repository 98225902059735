.client {
    width: 100%;
    background: #000;
    padding: 4em 0;
}
.clientContent {
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    grid-area: 2 / 1 / 3 / 2;
    left: 91px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.clientHeader h2{
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    line-height: 1.2em;
    letter-spacing: 0.2px;
    padding-bottom: 1.2em;
}
.clientContentTH h2 {
    font-size: 42px;
    font-weight: 500;
    font-family: 'Kanit', sans-serif;
}
.clientItem {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8em;
    width: 70%;
}
.clientItem img {
    width: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
    .clientContent {
        margin: 0px 0px 10px calc((100% - 650px) * 0.5);
        left: 30px;
    }
    .clientHeader h2 {
        font-size: 42px;
    }
    .clientItem {
        gap: 4em;
        width: 100%;
    }
    .clientItem img {
        width: 80%;
    }
    .clientContentTH h2 {
        font-size: 34px;
        font-weight: 500;
    }
    
  }
@media (min-width: 220px) and (max-width: 550px) {
    .clientContent {
        margin: 0px 0px 10px calc((100% - 330px) * 0.5);
        left: 0px;
    }
    .clientHeader h2 {
        font-size: 28px;
    }
    .clientItem {
        gap: 2em;
        width: 100%;
    }
    .clientItem img {
        width: 70%;
    }
    .clientContentTH h2 {
        font-size: 30px;
        font-weight: 500;
    }
  }