@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&family=Kodchasan:wght@300;400;500;600;700&family=Nunito:wght@300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}

body {
  font-family: Arial , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

:root {
  --fcolor1:#EC6C10;
  --fcolor2: #1f1f1f;
  --fcolor3:#414141;
  --fcolor4:#FDFFED
}