.wantToget {
    width: 100%;
    height: auto;
}
.wtVideo {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position:center center;
    opacity: 1;
    position: absolute;
    z-index: -1;
    
}
.wtContent {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    width: 928px;
    position: relative;
    margin: 0px 0px 200px calc((100% - 980px) * 0.5);
    left: 26px;
    grid-area: 1 / 1 / 2 / 2;
    justify-self: start;
    align-self: start;
    text-align: center;
}
.wtContent span {
    font-family: 'kodchasan',serif;
    font-weight: bold;
    color: #fff;
    font-size: 25px;
    font-style: italic;
}
.wtContent span:nth-child(1){
    margin: 200px 0px 0px calc((100% - 980px) * 0.5);
    left: 26px;
}
@media (max-width: 1024px) {
    .wantToget {
      background-image: url(/public/images/bg2.webp);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .wtVideo {
        display: none;
    }
  }
@media (min-width: 768px) and (max-width: 1024px) {
    .wtContent {
        width: 730px;
        gap: 1em;
        margin: 0;
        left: 0;
    }
    .wtContent span:nth-child(1){
        margin: 30px 0px 10px calc((100% - 768px) * 0.5);
        left: 26px;
    }
    
  }
@media (min-width: 220px) and (max-width: 550px) {
    .wantToget {
        padding-bottom: 1em;
    }
    .wtContent {
        width: 280px;
        gap: .5em;
        margin: 0 auto;
        left: 0;

    }
    .wtContent span {
        font-size: 16px;
    }
    .wtContent span:nth-child(1){
        margin: 30px 0px 10px calc((100% - 280px) * 0.5);
        left: 26px;
    }
  }